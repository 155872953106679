.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background-color: #1976d2;
}

body {
  margin: 0;
  overflow: hidden; 
}
 